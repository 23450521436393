import { useState } from "react";
import cn from "classnames";
import { useSecurityDocumentsExistFlag } from "../../../effects/useSecurityDocumentsExistFlag";
import { isRequestNone, isRequesting } from "../../../utils/request-state.utils";
import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";
import { RoleActionBlocker } from "../../access/RoleActionBlocker";
import { roles } from "../../../constants/roles";
import { ActionBlocker } from "../../access/ActionBlocker";
import { FeatureButton } from "../../access/FeatureButton";
import { DealDocumentsPopup } from "./DealDocumentsPopup";
import IconSVG from "../../../styles/svg-icons";

interface Props {
    className?: string
    security: {
        securityId: number
        isinCusip: string
        ticker: string
    }
    requiredFeature?: SubscriptionFeature | null
    disabled?: boolean
    checkDocumentsExist?: boolean
}

export function ViewDealDocumentsButton({ 
    className = "",
    security,
    disabled = false,
    checkDocumentsExist = false,
    requiredFeature = SubscriptionFeature.CanAccessDealDocuments }: Props) {
    const [requestState, isDocumentsExist] = useSecurityDocumentsExistFlag(security.isinCusip, !checkDocumentsExist);
    const [popupVisible, setPopupVisible] = useState(false);

    if (checkDocumentsExist && (
        !isDocumentsExist || isRequestNone(requestState) || isRequesting(requestState))) {
        return null;
    }

    return (
        <>
            <RoleActionBlocker roles={roles.getAllRolesExclude(roles.Media, roles.Viewer)}>
                {blockedByRole => (
                    <ActionBlocker overrideRequiredFeatures={blockedByRole} feature={requiredFeature ?? undefined}>
                        <FeatureButton
                            className={cn("btn-link", className)}
                            type="button"
                            disabled={disabled}
                            onClick={() => setPopupVisible(true)}
                        >
                            <IconSVG name="docs" width={16} height={16} />
                            Docs
                        </FeatureButton>
                    </ActionBlocker>
                )}
            </RoleActionBlocker>
            {popupVisible &&
                <DealDocumentsPopup security={security} onClose={() => setPopupVisible(false)} />}
        </>
    );
}