import React, { memo, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Document, DocumentStoreType } from '../../types/document/Document';
import { DragFileZone } from '../common/DragFileZone';
import { Table } from '../bidding/common/table';
import { ColumnBuilder } from '../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../bidding/common/table/types/ColumnDefinition';
import { SORT, SORTING_TYPE } from '../../constants/sort';
import { ProgressCircle } from '../controls';
import FileIcon from '../controls/FileIcon';
import { isEqual } from 'lodash';
import { useDownloadDocument } from './useDownloadDocument';
import { useUploadDocument } from './useUploadDocument';
import { SelectFile } from '../grid/SelectFile';
import IconSVG from '../../styles/svg-icons';
import { OnHoverTooltip, Preloader } from '../common';
import { errorActions } from '../../actions';
import { constants, errorMessages } from '../../constants';
import { isRequesting } from '../../utils';

interface Props {
    documents: Document[];
    documentStoreType: DocumentStoreType;
    documentGroupKey?: string;
    headerVisible?: boolean;
    readonly?: boolean;
    onDelete?: (id: string) => void;
    onDocumentUploaded?: (document: Document[]) => void;
    acceptedExtensions?: string[];
}

function DocumentListComponent({ documents, headerVisible = true, readonly = true, onDelete, documentGroupKey, onDocumentUploaded, documentStoreType, acceptedExtensions }: Props) {
    const dispatch = useDispatch();
    const uploadedDocuments = useRef<Document[]>([]);
    const [dragEnter, setDragEnter] = useState(false);
    const [uploadingFileNames, setUploadingFileNames] = useState<string[]>([]);
    const { downloadDocument, downloadAllDocuments, requestStatusDownloadAll } = useDownloadDocument(documentStoreType);
    const { onUploadDocument, progress } = useUploadDocument(documentStoreType);

    const uploadFile = async (file: File) => {
        setUploadingFileNames((names) => ([...names, file.name]));
        const newDocument = await onUploadDocument(file);
        if (newDocument) uploadedDocuments.current.push(newDocument)
        setUploadingFileNames(names => {
            return names.filter((name) => name !== file.name);
        });
    }

    useEffect(() => {
        if (uploadedDocuments.current.length && !uploadingFileNames.length) {
            onDocumentUploaded && onDocumentUploaded(uploadedDocuments.current);
            uploadedDocuments.current = [];
        }
        // eslint-disable-next-line
    }, [uploadedDocuments, uploadingFileNames])

    const handleDrop = (files: FileList) => {
        if (readonly || !files) return;
        [...files].forEach((file) => {
            const splitFile = file.name?.split('.');
            const fileExtension = splitFile[splitFile.length - 1];
            if (file.size > 1024 * 1024 * constants.documentMaxFilesizeInMb) {
                dispatch(errorActions.error(
                    '',
                    errorMessages.documentMaxFileSizeMessageText(constants.documentMaxFilesizeInMb),
                    errorMessages.documentMaxFileSizeMessageTitle)
                );
                return;
            }
            if (acceptedExtensions && !acceptedExtensions.some(ext => ext.toLowerCase() === fileExtension.toLowerCase())) {
                dispatch(errorActions.error(
                    '',
                    errorMessages.documentInvalidFileTypeText(fileExtension, acceptedExtensions.map(ext => `.${ext}`).join(',')),
                    errorMessages.documentInvalidFileTypeTitle)
                );
                return;
            }
            uploadFile(file);
        })
    }

    const renderActionButton = (documentId: string) =>
        !readonly && documentId
            ? (
                <>
                    <div onClick={() => downloadDocument(documentId)}>
                        <IconSVG name="downloadTemplate" width={16} height={16} />
                    </div>
                    <div>
                        <i onClick={() => onDelete && onDelete(documentId)} className="icon icon-delete" />
                    </div>
                </>
            ) : (
                <OnHoverTooltip overlay="Click to download this document">
                    <div onClick={() => downloadDocument(documentId)}>
                        <IconSVG name="downloadTemplate" width={16} height={16} />
                    </div>
                </OnHoverTooltip>
            )

    const getTableColumns = () => {
        const columns: IColumnDefinition<Document>[] = [{
            columnKey: 'name',
            renderColumnContent: document => (
                <div className="text-ellipsis" onClick={() => downloadDocument(document.id)}>
                    <OnHoverTooltip overlay={document.name}>
                        <FileIcon filename={document.name} />
                        {document.name}
                    </OnHoverTooltip>
                </div>
            ),
            renderColumnHeaderContent: () => 'Name',
            headerClassName: 'data-list-cell-doc-name',
            bodyClassName: 'data-list-cell-doc-name',
            sortingField: 'name',
            sortingType: SORTING_TYPE.string,
        }, {
            columnKey: 'uploadTime',
            renderColumnContent: document => {
                if (document.id) {
                    return moment(document.uploadTime).format(constants.dateFormatDoubleDay)
                }
                return <ProgressCircle progress={progress[document.name]} />;
            },
            renderColumnHeaderContent: () => 'Date',
            headerClassName: 'data-list-cell-doc-date',
            bodyClassName: 'data-list-cell-doc-date',
            sortingField: 'uploadTime',
            sortingType: SORTING_TYPE.date,
        }, {
            columnKey: 'action',
            renderColumnContent: document => renderActionButton(document.id),
            renderColumnHeaderContent: () => '',
            headerClassName: 'data-list-cell-doc-btn',
            bodyClassName: 'data-list-cell-doc-btn',
        }]

        return columns.map(c => new ColumnBuilder(c));
    }

    const getTableData = (): Document[] => {
        if (uploadingFileNames.length) {
            const newDocuments: Document[] = uploadingFileNames.map((name) => ({
                name,
                id: '',
                uploadTime: new Date()
            }))
            return documents.concat(newDocuments)
        }
        return documents
    }

    return (
        <>
            {(!!documents.length || !readonly) &&
                <div className="section-title">
                    {headerVisible && <h2>Documents</h2>}
                    {documentGroupKey && (
                        <div className="flex-item-right download-all">
                            <Preloader small={true} fullScreen={false}
                                inProgress={isRequesting(requestStatusDownloadAll)} text="Downloading...">
                                <div className="btn btn-link" onClick={() => downloadAllDocuments(documentGroupKey)}>
                                    <IconSVG name="downloadTemplate" width={16} height={16} /> Download All
                                </div>
                            </Preloader>
                        </div>
                    )}
                </div>
            }
            <div className={cn('documents-section', { 'drag-enter-content': dragEnter })}>
                <div className="component-file-upload-list">
                    <Table
                        columns={getTableColumns()}
                        dataItems={getTableData()}
                        defaultSortBy="uploadTime"
                        defaultSortByDirection={SORT.DESC}
                    />
                </div>
                {
                    !readonly && (
                        <DragFileZone
                            onFiles={handleDrop}
                            onDragEnter={() => setDragEnter(true)}
                            onDragLeave={() => setDragEnter(false)}
                        >
                            {dragEnter ?
                                (<>Uploading...</>) :
                                (<>
                                    <IconSVG name="upload-doc" width={24} height={24} />
                                    <div className="info">
                                        <p>
                                            Drag & Drop file here or
                                            <SelectFile
                                                onFiles={handleDrop}
                                                acceptedExtensions={acceptedExtensions?.map(extension => `.${extension}`).join(',')}
                                            />
                                        </p>
                                        <p className="text-warm-grey text-sm">Format: csv, doc, docx, pdf, ppt, pptx, xls, xlsx</p>
                                    </div>
                                </>)
                            }
                        </DragFileZone>
                    )
                }
            </div>
        </>
    );
}

export const DocumentList = memo(
    DocumentListComponent,
    (prevProps: Props, nextProps: Props) =>
        isEqual(prevProps.documents, nextProps.documents) &&
        prevProps.readonly === nextProps.readonly
)
